import { defineComponent } from 'vue';
export default defineComponent({
    name: 'LedStripDebug',
    props: {
        value: {
            type: String,
            required: true
        }
    },
    methods: {
        setAll(color) {
            let pattern = '';
            for (let index = 0; index < 299; ++index) {
                pattern += `${color} `;
            }
            console.log(`Pattern ${pattern}`);
            this.$emit('input', `${pattern}${color}`);
        },
        setRainbow() {
            const colors = ['FFFFFF', 'FF0000', '00FF00', '0000FF'];
            let pattern = '';
            for (let index = 0; index < 299; ++index) {
                pattern += `${colors[index % 4]} `;
            }
            console.log(`Pattern ${pattern}`);
            this.$emit('input', `${pattern}${colors[299 % 4]}`);
        }
    }
});
