import { defineComponent } from 'vue';
export default defineComponent({
    name: 'RoomCharacterEdit',
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    created() {
        this.addCharacter('communication', 0);
        this.addCharacter('teamwork', 0);
        this.addCharacter('active', 0);
        this.addCharacter('sharpness', 0);
        this.addCharacter('agility', 0);
    },
    methods: {
        addCharacter(name, value) {
            if (this.config.character === undefined) {
                this.config.character = {};
            }
            if (this.config.character[name] === undefined) {
                this.config.character[name] = value;
            }
        }
    }
});
