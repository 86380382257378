import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { Timer, LevelStatusBirds, Level, InfoBar, MessageBar, FullscreenMessage, RoomDuration, IntroductionSlide } from '@/components/Widgets';
import { Animation } from '@/Animation';
import './RoomCatapult.scss';
import { TeamGameType } from '@/types/teams';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'CatapultRoom',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        LevelStatusBirds,
        Level,
        InfoBar,
        MessageBar,
        FullscreenMessage,
        RoomDuration,
        IntroductionSlide
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        animation: Animation
    }),
    computed: {
        gameMessage() {
            return this.translation('birdsChallenge');
        },
        postgametext() {
            if (this.level < 10 || this.score < 100) {
                return this.translation('reachedLevel', { level: `${this.level}` });
            }
            else {
                return this.translation('reactionRoomHighLevel');
            }
        },
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        cheating() {
            return this.$store.direct.state.rooms.cheating;
        },
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        teamType() {
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if (!status) {
                return TeamGameType.Beatout;
            }
            if (!status.team) {
                return TeamGameType.Beatout;
            }
            return status.team.game_type;
        },
        timeleft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        forbiddenColors() {
            return this.$store.direct.state.rooms.forbiddenColors;
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gamestate === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        }
    },
    methods: {
        onEvent(eventType, data) {
            if (eventType === 'clock' && data.status === 'start') {
                new Audio(require('@/assets/sound/go.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up' && data.diff > 1) {
                console.log('UP Diff: ' + data.diff);
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'down' && data.diff < -1) {
                console.log('Down Diff: ' + data.diff);
                new Audio(require('@/assets/sound/crow.wav')).play();
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        },
        beforeEnter: function (element) {
            element.style.opacity = '0';
        },
    }
});
