import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { Timer, LevelStatus, Level, InfoBar, MessageBar, RoomDuration, IntroductionSlide, ButtonPressBox } from '@/components/Widgets';
import { Animation } from '@/Animation';
import RoomSpiderwebControl from './RoomSpiderwebControl.vue';
import './RoomSpiderweb.scss';
import { TeamGameType } from '@/types/teams';
import useLocation from '@/compositions/useLocation';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'SpiderRoom',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        RoomSpiderwebControl,
        Testgame,
        Timer,
        LevelStatus,
        Level,
        InfoBar,
        MessageBar,
        RoomDuration,
        IntroductionSlide,
        ButtonPressBox
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({}),
    computed: {
        postgametext() {
            if (this.level <= 10) {
                return this.translation('reachedLevel', { level: `${this.level}` });
            }
            else {
                return this.translation('reactionRoomHighLevel');
            }
        },
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        ingamestate() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        teamType() {
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if (!status) {
                return TeamGameType.Beatout;
            }
            if (!status.team) {
                return TeamGameType.Beatout;
            }
            return status.team.game_type;
        },
        status() {
            return this.$store.direct.state.rooms.status[this.roomid];
        },
        timeleft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        currentLocation() {
            const location = useLocation();
            return location.currentLocationId.value;
        },
        going_through() {
            for (const hole of this.status.holes_status) {
                if (hole == 'selected') {
                    return true;
                }
            }
            return false;
        },
        message_during_gameplay() {
            return this.going_through ? this.translation('spiderAfterCrawl') : this.translation('spiderCrawl');
        },
        nr_buttons_pressed() {
            return this.status.buttons_pressed_start + this.status.buttons_pressed_end;
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gamestate === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        },
        mistakesLeft(newValue, oldValue) {
            if (newValue < oldValue && !this.status.is_counting_members)
                new Audio(require('@/assets/sound/brokenglass.mp3')).play();
        },
        nr_buttons_pressed(newValue, oldValue) {
            if (oldValue < newValue)
                new Audio(require('@/assets/sound/button_click.mp3')).play();
        },
        going_through(newVal) {
            if (newVal)
                new Audio(require('@/assets/sound/select.mp3')).play();
        }
    },
    methods: {
        onEvent(eventType, data) {
            if (eventType === 'score' && data.status === 'up') {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'down' && data.diff > -50) {
                new Audio(require('@/assets/sound/brokenglass.mp3')).play();
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        }
    }
});
