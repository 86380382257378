import { defineComponent } from 'vue';
import useLocation from '@/compositions/useLocation';
import './RoomRepeat.scss';
export default defineComponent({
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        config: null,
        status: null,
    }),
    setup: () => ({
        ...useLocation()
    }),
    computed: {
        current_serie() {
            var _a, _b;
            return ((_a = this.status) === null || _a === void 0 ? void 0 : _a.current_serie) ? (_b = this.status) === null || _b === void 0 ? void 0 : _b.current_serie : [''];
        },
        current_target_length() {
            var _a, _b;
            return ((_a = this.status) === null || _a === void 0 ? void 0 : _a.current_target_length) ? (_b = this.status) === null || _b === void 0 ? void 0 : _b.current_target_length : 0;
        },
        total_serie() {
            var _a;
            if ((_a = this.status) === null || _a === void 0 ? void 0 : _a.total_serie) {
                return this.status.total_serie;
            }
            return [''];
        },
        nextButtonUrl() {
            var _a;
            const nextId = this.total_serie[this.current_target_length - 1];
            if (nextId)
                return (_a = this.findButtonById(nextId)) === null || _a === void 0 ? void 0 : _a.url;
            else
                return "";
        },
        currentSerieLength() {
            var _a, _b;
            return ((_a = this.current_serie) === null || _a === void 0 ? void 0 : _a.length) ? (_b = this.current_serie) === null || _b === void 0 ? void 0 : _b.length : 0;
        }
    },
    watch: {
        status: {
            handler() {
                console.log('Status changed');
            },
            deep: true
        }
    },
    async mounted() {
        await this.$store.direct.dispatch.rooms.subscribeStatus(this.roomid);
        await this.$store.direct.dispatch.rooms.subscribe(this.roomid);
        this.config = this.$store.direct.state.rooms.config[this.roomid];
        // @ts-ignore
        this.status = this.$store.direct.state.rooms.status[this.roomid];
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
        this.$store.direct.dispatch.rooms.unsubscribeStatus(this.roomid);
    },
    methods: {
        findButtonById(buttonId) {
            var _a;
            if (this.config) {
                return (_a = this.config.buttons) === null || _a === void 0 ? void 0 : _a.find((button) => button.id === buttonId);
            }
        },
        getButtonUrl(buttonId) {
            var _a;
            return (_a = this.findButtonById(buttonId)) === null || _a === void 0 ? void 0 : _a.url.replace('dekluis.ddns.net', this.currentLocation.apiurl);
        },
        currentPosToPress(index) {
            let className = '';
            className += (index == this.currentSerieLength) ? 'currentPosToPress' : '';
            className += (index > this.current_target_length - 1) ? ' outOfCurrentTarget' : '';
            return className;
        },
    }
});
