import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { Animation } from '@/Animation';
import { Timer, LevelStatus, Level, Combination, MessageBar, FullscreenMessage, RoomDuration, IntroductionSlide } from '@/components/Widgets';
import './RoomCombination.scss';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'RoomCombination',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        LevelStatus,
        Level,
        Combination,
        MessageBar,
        FullscreenMessage,
        RoomDuration,
        IntroductionSlide
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        animation: Animation,
        scoreDown: false
    }),
    computed: {
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        ingameState() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        combinationCountdown() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        playingTeam() {
            return this.$store.direct.state.rooms.playingTeam;
        },
        motion() {
            return this.$store.direct.state.rooms.motion;
        },
        members() {
            var _a;
            if (((_a = this.playingTeam) === null || _a === void 0 ? void 0 : _a.members) === undefined) {
                return 0;
            }
            return this.playingTeam.members.length;
        }
    },
    watch: {
        gamestate(newValue) {
            // TODO use vue for this
            const body = document.querySelectorAll('body')[0];
            if (body === undefined) {
                console.error('Could not find body');
                return;
            }
            body.classList.remove('pregame', 'playing', 'postgame', 'stopped', 'introduction');
            body.classList.add(newValue);
        },
        level() {
            this.animation.animateLevelChange(() => { });
            setTimeout(() => {
                Animation.animateLevel('.level .level_icon', () => { });
            }, 3000);
        }
    },
    methods: {
        onEvent(eventType, data) {
            console.log('NEW EVENT OF TYPE ' + eventType + ':', data);
            if (eventType === 'cheat') {
                new Audio(require('@/assets/sound/laser.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up') {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'down') {
                new Audio(require('@/assets/sound/mistake.mp3')).play();
                this.scoreDown = true;
                setTimeout(() => { this.scoreDown = false; }, 3000);
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
            else if (eventType === 'clock' && data.status === 'started') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        }
    }
});
