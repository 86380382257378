import { defineComponent } from 'vue';
import ChromaKeySliders from './ChromaKeySliders.vue';
import fragmentshader from './fragmentShader';
import vertexshader from './vertexShader';
export default defineComponent({
    name: 'ChromaKey',
    components: {
        ChromaKeySliders
    },
    data: () => ({
        gl: null,
        chromakey: {
            enabled: false,
            screen: '#FFFFFF',
            weight: 0,
            balance: 0,
            clipblack: 0,
            clipwhite: 0
        },
        pickingMode: false,
        video: undefined,
        vertexBuffer: null,
        indexBuffer: null,
        textureCoordBuffer: null,
        texture: null,
        enabled: null,
        screen: null,
        source: null,
        screenWeight: null,
        balance: null,
        clipBlack: null,
        clipWhite: null,
        initialized: false,
        initialized2: 0
    }),
    computed: {
        showSliders() {
            return this.$route.query.debug === 'true';
        },
        canvas() {
            return this.$refs.chromacanvas;
        },
        sliders() {
            // @ts-ignore
            return this.$refs.chromaKeySliders;
        }
    },
    watch: {
        chromakey: {
            handler() {
                if (this.initialized)
                    this.setAttributes();
            },
            deep: true
        },
        pickingMode() {
            if (this.initialized)
                this.setAttributes();
        }
    },
    mounted() {
        const canvas = this.$refs.chromacanvas;
        if (canvas === undefined) {
            console.error('Chromakey.vue: canvas not available');
            return;
        }
        canvas.addEventListener('mousedown', e => { this.doPickColor(e); });
        // @ts-ignore
        this.gl = canvas.getContext('webgl', { premultipliedAlpha: false, preserveDrawingBuffer: true });
        // @ts-ignore
        this.video = document.querySelectorAll('video')[0];
        requestAnimationFrame(this.render);
        this.setAttributes();
    },
    methods: {
        setPickingMode(mode) {
            this.pickingMode = mode;
        },
        init() {
            if (!this.initialized && this.initialized2 >= 3) {
                this.initGeometry();
                this.initShaders();
                this.initTexture();
                this.initialized = true;
            }
            if (this.initialized2 < 3 && this.video && this.video.videoWidth && this.video.videoWidth > 0) {
                this.initialized2 += 1;
            }
        },
        hexToRGBA(hex) {
            let c;
            if (/^#([\dA-Fa-f]{3}){1,2}$/.test(hex)) {
                /* eslint-disable unicorn/prefer-spread */
                c = hex.slice(1).split('');
                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = '0x' + c.join('');
                // @ts-ignore
                return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 255 };
            }
            return { r: 0, g: 0, b: 0, a: 0 };
        },
        setAttributes() {
            if (this.chromakey && this.gl !== null) {
                console.log('ChromaKey setAttributes', this.chromakey);
                const rgb = this.hexToRGBA(this.chromakey.screen);
                this.gl.uniform1i(this.enabled, this.chromakey.enabled && !this.pickingMode ? 1 : 0);
                this.gl.uniform4f(this.screen, rgb.r / 255, rgb.g / 255, rgb.b / 255, 1);
                this.gl.uniform1f(this.screenWeight, this.chromakey.weight / 100);
                this.gl.uniform1f(this.balance, this.chromakey.balance / 100);
                this.gl.uniform1f(this.clipBlack, this.chromakey.clipblack / 100);
                this.gl.uniform1f(this.clipWhite, this.chromakey.clipwhite / 100);
                if (this.canvas !== undefined) {
                    this.canvas.style.cursor = this.pickingMode ? 'crosshair' : 'inherit';
                }
            }
        },
        render() {
            if (this.initialized) {
                this.updateTexture();
                this.drawScene();
            }
            this.init();
            requestAnimationFrame(this.render);
        },
        updateTexture() {
            if (this.gl === null)
                return;
            if (this.video === undefined)
                return;
            const level = 0;
            const internalFormat = this.gl.RGB;
            const sourceFormat = this.gl.RGB;
            const sourceType = this.gl.UNSIGNED_BYTE;
            this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
            // @ts-ignore
            this.gl.texImage2D(this.gl.TEXTURE_2D, level, internalFormat, sourceFormat, sourceType, this.video);
        },
        drawScene() {
            if (this.canvas === undefined)
                return;
            if (this.gl === null)
                return;
            this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
            this.gl.clearColor(1, 0, 0, 1);
            this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
            this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
        },
        initGeometry() {
            if (this.gl === null)
                return;
            const vertices = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0];
            this.vertexBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.indexBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
            this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([3, 2, 1, 3, 1, 0]), this.gl.STATIC_DRAW);
            this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, null);
            this.textureCoordBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureCoordBuffer);
            const textureCoordinates = [0, 0, 0, 1, 1, 1, 1, 0];
            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), this.gl.STATIC_DRAW);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureCoordBuffer);
        },
        __init_vertexShader() {
            if (this.gl === null)
                return null;
            const vertShader = this.gl.createShader(this.gl.VERTEX_SHADER);
            if (vertShader === null)
                return null;
            this.gl.shaderSource(vertShader, vertexshader);
            this.gl.compileShader(vertShader);
            const success = this.gl.getShaderParameter(vertShader, this.gl.COMPILE_STATUS);
            if (!success) {
                console.error('could not compile vertShader:' + this.gl.getShaderInfoLog(vertShader));
            }
            return vertShader;
        },
        __init_fragmentShader() {
            if (this.gl === null)
                return null;
            const fragShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
            if (fragShader === null)
                return null;
            this.gl.shaderSource(fragShader, fragmentshader);
            this.gl.compileShader(fragShader);
            const success = this.gl.getShaderParameter(fragShader, this.gl.COMPILE_STATUS);
            if (!success) {
                // Something went wrong during compilation; get the error
                console.error('could not compile fragShader:' + this.gl.getShaderInfoLog(fragShader));
            }
            return fragShader;
        },
        __create_program(vertShader, fragShader) {
            if (this.gl === null)
                return null;
            const shaderProgram = this.gl.createProgram();
            if (shaderProgram === null)
                return null;
            this.gl.attachShader(shaderProgram, vertShader);
            this.gl.attachShader(shaderProgram, fragShader);
            this.gl.linkProgram(shaderProgram);
            this.gl.useProgram(shaderProgram);
            return shaderProgram;
        },
        // eslint-disable-next-line max-lines-per-function
        initShaders() {
            if (this.gl === null)
                return;
            const vertShader = this.__init_vertexShader();
            const fragShader = this.__init_fragmentShader();
            if (vertShader === null || fragShader === null) {
                console.error('Could not create shaders');
                return;
            }
            const shaderProgram = this.__create_program(vertShader, fragShader);
            if (shaderProgram === null) {
                console.error('Could not create program shader');
                return;
            }
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
            this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
            const coord = this.gl.getAttribLocation(shaderProgram, 'coordinates');
            this.gl.vertexAttribPointer(coord, 3, this.gl.FLOAT, false, 0, 0);
            this.gl.enableVertexAttribArray(coord);
            const number = 2; // every coordinate composed of 2 values
            const normalize = false; // don't normalize
            const stride = 0; // how many bytes to get from one set to the next
            const offset = 0; // how many bytes inside the buffer to
            const tcoord = this.gl.getAttribLocation(shaderProgram, 'texCoord');
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureCoordBuffer);
            this.gl.vertexAttribPointer(tcoord, number, this.gl.FLOAT, normalize, stride, offset);
            this.gl.enableVertexAttribArray(tcoord);
            this.source = this.gl.getUniformLocation(shaderProgram, 'source');
            if (this.source !== null)
                this.gl.uniform1i(this.source, 0);
            this.enabled = this.gl.getUniformLocation(shaderProgram, 'enabled');
            this.screen = this.gl.getUniformLocation(shaderProgram, 'screen');
            this.screenWeight = this.gl.getUniformLocation(shaderProgram, 'screenWeight');
            this.balance = this.gl.getUniformLocation(shaderProgram, 'balance');
            this.clipBlack = this.gl.getUniformLocation(shaderProgram, 'clipBlack');
            this.clipWhite = this.gl.getUniformLocation(shaderProgram, 'clipWhite');
            this.setAttributes();
        },
        initTexture() {
            if (this.gl === null)
                return;
            if (this.video === undefined)
                return;
            this.texture = this.gl.createTexture();
            this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
            const level = 0;
            const internalFormat = this.gl.RGB;
            const sourceFormat = this.gl.RGB;
            const sourceType = this.gl.UNSIGNED_BYTE;
            // @ts-ignore
            this.gl.texImage2D(this.gl.TEXTURE_2D, level, internalFormat, sourceFormat, sourceType, this.video);
            this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
            this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
            this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
            this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
            requestAnimationFrame(this.render);
        },
        doPickColor(e) {
            if (!this.pickingMode)
                return;
            if (this.canvas === undefined)
                return;
            if (this.sliders === undefined)
                return;
            if (this.gl === null)
                return;
            const rect = this.canvas.getBoundingClientRect();
            const x = this.canvas.width * (e.clientX - rect.left) / rect.width;
            const y = this.canvas.height * (rect.height - (e.clientY - rect.top)) / rect.height;
            const pixel = new Uint8Array(4);
            this.gl.readPixels(x, y, 1, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, pixel);
            const dColor = pixel[2] + 256 * pixel[1] + 65536 * pixel[0];
            const dcolorstr = ('#' + ('0000' + dColor.toString(16)).slice(-6));
            // @ts-ignore
            this.sliders.colorPicked(dcolorstr);
        }
    }
});
