import { defineComponent } from 'vue';
import { API } from '@/BeatoutAPI';
import { Selector } from '@/components/UI';
export default defineComponent({
    name: 'CameraRoomEdit',
    components: {
        Selector
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        options: [{ value: null, text: 'Select a brain' }],
        objects: [{ value: null, text: 'Select an object' }],
        selectedObjects: []
    }),
    watch: {
        selectedObjects: {
            handler(newValue) {
                let index = 0;
                newValue.forEach(object => {
                    const person = this.config.persons[index];
                    if (object !== null && person !== undefined) {
                        person.objects.push(object);
                        this.clearSelectedObjects();
                    }
                    index += 1;
                });
            },
            deep: true
        }
    },
    async mounted() {
        const data = await API.getBrains();
        data.forEach(option => {
            this.options.push({ value: option._id, text: option._id });
        });
        this.createBrainObjects();
        this.clearSelectedObjects();
    },
    methods: {
        clearSelectedObjects() {
            this.selectedObjects = [];
            for (const _person of this.config.persons) {
                this.selectedObjects.push(null);
            }
        },
        personList(personIndex) {
            const person = this.config.persons[personIndex];
            if (person === undefined)
                return '';
            let personList = '';
            person.objects.forEach(object => {
                personList += object + ' ';
            });
            return personList;
        },
        addNewPerson() {
            const id = Math.random().toString(36).slice(7);
            this.config.persons.push({ id, url: '', name: '', objects: [] });
            this.clearSelectedObjects();
        },
        removePerson(key) {
            this.config.codes.splice(key, 1);
            this.clearSelectedObjects();
        },
        async createBrainObjects() {
            const brain = await API.getBrain(this.config.brain);
            this.objects = [{ value: null, text: 'Select an object' }];
            brain.objects.forEach(object => {
                this.objects.push({ value: object.id, text: object.id });
            });
        }
    }
});
