import { defineComponent } from 'vue';
export default defineComponent({
    name: 'GeneralRoomEdit',
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    computed: {
        duration: {
            get() {
                return this.config.game_duration || 0;
            },
            set(value) {
                this.config.game_duration = value;
            }
        }
    }
});
