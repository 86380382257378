import { defineComponent } from 'vue';
import SearchControl from './RoomSearch/SearchControl.vue';
import CameraControl from './RoomCamera/CameraControl.vue';
import ReactionControl from './RoomReaction/ReactionControl.vue';
import RoomSpiderwebControl from './RoomSpiderweb/RoomSpiderwebControl.vue';
import RoomRepeatControl from './RoomRepeat/RoomRepeatControl.vue';
import GameStateChanger from '../RoomDebug/GameStateChanger.vue';
import { GameType, GameState } from '@/types/rooms';
export default defineComponent({
    name: 'RoomControl',
    components: {
        SearchControl,
        ReactionControl,
        RoomSpiderwebControl,
        RoomRepeatControl,
        CameraControl,
        GameStateChanger
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        config: null,
        status: null
    }),
    computed: {
        roomType() {
            if (this.config) {
                return this.config.type;
            }
            return GameType.Unknown;
        },
        gameState() {
            if (this.status) {
                return this.status.state;
            }
            return GameState.Stopped;
        },
        isCluedo() {
            return this.roomType === GameType.Cluedo;
        },
        isReaction() {
            return this.roomType === GameType.Reaction;
        },
        isCamera() {
            return this.roomType === GameType.Camera;
        },
        isSpiderWeb() {
            return this.roomType === GameType.SpiderWeb;
        },
        isRepeat() {
            return this.roomType === GameType.Repeat;
        }
    },
    mounted() {
        this.$store.direct.dispatch.rooms.subscribe(this.roomid).then(() => {
            var _a, _b;
            this.config = (_a = this.$store.direct.state.rooms.config[this.roomid]) !== null && _a !== void 0 ? _a : null;
            this.status = (_b = this.$store.direct.state.rooms.status[this.roomid]) !== null && _b !== void 0 ? _b : null;
        }).catch(error => {
            console.error('Could not subscribe in RoomConfig', error);
        });
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
    }
});
