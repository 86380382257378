const vertextShader = `

precision mediump float;
attribute vec3 coordinates;
varying vec2 vTexCoord;
attribute vec2 texCoord;
uniform vec4 screen;
uniform float balance;
varying float screenSat;
varying vec3 screenPrimary;
void main(void) {
  float fmin = min(min(screen.r, screen.g), screen.b); // Min. value of RGB
  float fmax = max(max(screen.r, screen.g), screen.b); // Max. value of RGB
  float secondaryComponents;
  screenPrimary = step(fmax, screen.rgb);
  secondaryComponents = dot(1.0 - screenPrimary, screen.rgb);
  screenSat = fmax - mix(secondaryComponents - fmin, secondaryComponents / 2.0, balance);
  // convert back to OpenGL coords
  gl_Position = vec4(coordinates, 1.0);
  vTexCoord = texCoord;
}
`;
export default vertextShader;
