import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame } from '@/components/Widgets/GameStates';
import { Webcam, RoomDuration, Level, Timer, MessageBar, FullscreenMessage } from '@/components/Widgets';
import ChromaKey from '@/components/General/ChromaKey';
import './RoomCamera.scss';
export default defineComponent({
    name: 'CameraRoom',
    components: {
        Webcam,
        Pregame,
        Postgame,
        Stoppedgame,
        ChromaKey,
        RoomDuration,
        Level,
        Timer,
        MessageBar,
        FullscreenMessage
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        img: null,
        camera: null,
        deviceId: null,
        devices: [],
        lastPictureTaken: 0,
        objects: {}
    }),
    computed: {
        gameState() {
            return this.$store.direct.state.rooms.gameState;
        },
        ingameState() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        sketch() {
            return this.$store.direct.state.rooms.sketch;
        },
        level() {
            return this.$store.direct.state.rooms.level;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        timeLeft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        now() {
            return this.$store.direct.state.clock.now;
        },
        message() {
            if (this.ingameState === 'pause')
                return 'Klik de camera knop om te starten';
            if (this.pending)
                return 'We controleren nu je foto';
            if (this.ingameState === 'correct')
                return 'Juist';
            if (this.ingameState === 'wrong')
                return 'Wrong';
            return undefined;
        },
        shouldTakePicture() {
            const timestamp = this.$store.direct.state.rooms.request_picture_timestamp;
            if (timestamp === 0)
                return false;
            const diff = (this.now - timestamp) % 3600; // timezone issues
            const lastTaken = this.now - this.lastPictureTaken < 10;
            const newTimestamp = diff < 10;
            // console.log('timestamp', timestamp, this.now, diff, newTimestamp, this.now - this.lastPictureTaken, lastTaken)
            return newTimestamp && !lastTaken;
        },
        pending() {
            return this.$store.direct.state.rooms.pending;
        },
        showResult() {
            if (this.pending)
                return true;
            return ['wrong', 'correct'].includes(this.ingameState);
        },
        device() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        getSketchPersonStyle() {
            return {
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto'
            };
        },
        postgametext() {
            if (this.level < 10 || this.score < 100) {
                return `Jullie zijn tot level ${this.level} geraakt!`;
            }
            else {
                return 'Jullie hebben de kamer uitgespeeld!';
            }
        }
    },
    watch: {
        camera(id) {
            this.deviceId = id;
        },
        devices() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            console.log(first, tail);
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        shouldTakePicture(value) {
            if (value) {
                this.onCapture();
            }
        }
    },
    methods: {
        async onCapture() {
            this.lastPictureTaken = this.now;
            // @ts-ignore
            this.img = this.$refs.webcam.capture();
            if (this.img === null)
                return;
            try {
                const { url, objects } = await this.$store.direct.dispatch.cv.upload(this.img);
                this.objects = objects;
                this.$store.direct.dispatch.rooms.updateStatus({
                    roomid: this.roomid,
                    data: {
                        action: 'camera',
                        data: {
                            objects: this.objects,
                            url
                        }
                    }
                });
            }
            catch (error) {
                console.error(`Could not upload image ${error}`);
            }
            this.$emit('gameEvent', { action: 'camera', data: 'hello' });
        },
        onStarted(stream) {
            console.log('On Started Event', stream);
        },
        onStopped(stream) {
            console.log('On Stopped Event', stream);
        },
        onStop() {
            // @ts-ignore
            this.$refs.webcam.stop();
        },
        onStart() {
            // @ts-ignore
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log('On Error Event', error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log('On Cameras Event', cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log('On Camera Change Event', deviceId);
        },
        getStyle(object) {
            if (this.$refs.img === undefined) {
                return {};
            }
            else {
                const img = this.$refs.img;
                const xratio = 60 / img.naturalWidth;
                const yratio = 60 / img.naturalHeight;
                return {
                    'left': 20 + (object.x * xratio) + 'vw',
                    'top': 7 + (object.y * yratio) + 'vh',
                    'width': (object.width * xratio) + 'vw',
                    'height': (object.height * yratio) + 'vh',
                    'position': 'absolute',
                    'border': '1px solid ' + object.color,
                    'border-radius': '5px',
                    'background-color': object.color.replace(')', ',0.2)')
                };
            }
        }
    }
});
