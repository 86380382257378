import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { Timer, LevelStatus, Level, InfoBar, MessageBar, FullscreenMessage, RoomDuration, IntroductionSlide } from '@/components/Widgets';
import { Animation } from '@/Animation';
import './RoomReaction.scss';
import { TeamGameType } from '@/types/teams';
import { ButtonColors } from '@/types/rooms';
import useLocation from '@/compositions/useLocation';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'ReactionRoom',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        LevelStatus,
        Level,
        InfoBar,
        MessageBar,
        FullscreenMessage,
        RoomDuration,
        IntroductionSlide
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        randomColors: {
            red: ButtonColors.Red,
            blue: ButtonColors.Blue,
            green: ButtonColors.Green,
            yellow: ButtonColors.Yellow
        }
    }),
    computed: {
        colorNames() {
            return {
                red: this.translation('red'),
                blue: this.translation('blue'),
                green: this.translation('green'),
                yellow: this.translation('yellow')
            };
        },
        gameMessage() {
            const infoIndex = Math.min(this.level - 1, this.levelInfo.length - 1);
            let message = this.levelInfo[infoIndex] + '<br>';
            this.forbiddenColors.forEach((color, index) => {
                let colorClass = color;
                if (this.level >= 8) {
                    colorClass = this.randomColors[color];
                }
                message += '<span class="warning ' + colorClass + '">' + this.colorNames[color] + '</span>';
                if (index < this.forbiddenColors.length - 1) {
                    message += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
                }
            });
            return message;
        },
        postgametext() {
            if (this.level <= 10) {
                return this.translation('reachedLevel', { level: `${this.level}` });
            }
            else {
                return this.translation('reactionRoomHighLevel');
            }
        },
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        teamType() {
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if (!status) {
                return TeamGameType.Beatout;
            }
            if (!status.team) {
                return TeamGameType.Beatout;
            }
            return status.team.game_type;
        },
        timeleft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        forbiddenColors() {
            return this.$store.direct.state.rooms.forbiddenColors;
        },
        currentLocation() {
            const location = useLocation();
            return location.currentLocationId.value;
        },
        levelInfo() {
            return [
                this.translation('reactionRoomDescrLevel1'),
                this.translation('reactionRoomDescrLevel2'),
                this.translation('reactionRoomDescrLevel3'),
                this.translation('reactionRoomDescrLevel4'),
                this.translation('reactionRoomDescrLevel5'),
                this.translation('reactionRoomDescrLevel6'),
                this.translation('reactionRoomDescrLevel7'),
                this.translation('reactionRoomDescrLevel8'),
                this.translation('reactionRoomDescrLevel9'),
                this.translation('reactionRoomDescrLevel10')
            ];
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gamestate === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        }
    },
    methods: {
        onEvent(eventType, data) {
            if (eventType === 'clock' && data.status === 'start') {
                new Audio(require('@/assets/sound/go.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up') {
                this.updateRandomColors();
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'down' && data.diff > -50) {
                new Audio(require('@/assets/sound/brokenglass.mp3')).play();
                this.updateRandomColors();
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        },
        updateRandomColors() {
            Object.keys(this.randomColors).forEach(key => {
                const index = Math.floor(Math.random() * Object.values(this.colorNames).length);
                // @ts-ignore
                this.randomColors[key] = Object.keys(this.colorNames)[index];
            });
        }
    }
});
