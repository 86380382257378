import { defineComponent } from 'vue';
import { CheckBox, Selector } from '@/components/UI';
export default defineComponent({
    name: 'CluedoRoomEdit',
    components: {
        CheckBox,
        Selector
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    computed: {
        persons() {
            const personlist = [];
            personlist.push({ value: '', text: 'Choose person' });
            for (const person of this.config.persons) {
                personlist.push({ value: person.name, text: person.name });
            }
            return personlist;
        },
        works() {
            const worklist = [];
            worklist.push({ value: '', text: 'Choose work' });
            for (const work of this.config.work) {
                worklist.push({ value: work.name, text: work.name });
            }
            return worklist;
        },
        tools() {
            const toollist = [];
            toollist.push({ value: '', text: 'Choose tool' });
            for (const tool of this.config.tool) {
                toollist.push({ value: tool.name, text: tool.name });
            }
            return toollist;
        }
    },
    mounted() {
        if (this.config.locations === undefined) {
            this.config.locations = [];
        }
        if (this.config.persons === undefined) {
            this.config.persons = [];
        }
        if (this.config.work === undefined) {
            this.config.work = [];
        }
        if (this.config.tool === undefined) {
            this.config.tool = [];
        }
    },
    methods: {
        addRoom() {
            this.config.locations.push({
                name: 'new',
                stripid: 'ledstrip',
                from_led: 1,
                to_led: 2,
                tutorial: false,
                windows: {
                    person: { id: '', from_led: 3, to_led: 4 },
                    work: { id: '', from_led: 5, to_led: 6 },
                    tool: { id: '', from_led: 7, to_led: 8 }
                }
            });
            this.$forceUpdate();
        },
        addPersons() {
            this.config.persons.push({
                name: 'new',
                url: 'url',
                button: 'button_id'
            });
            this.$forceUpdate();
        },
        addWork() {
            this.config.work.push({
                name: 'new',
                url: 'url',
                button: 'button_id'
            });
            this.$forceUpdate();
        },
        addTool() {
            this.config.tool.push({
                name: 'new',
                url: 'url',
                button: 'button_id'
            });
            this.$forceUpdate();
        },
        removeRoom(name) {
            const index = this.config.locations.findIndex(room => room.name === name);
            if (index !== undefined) {
                this.config.locations.splice(index, 1);
            }
            this.$forceUpdate();
        },
        removePerson(name) {
            const index = this.config.persons.findIndex(person => person.name === name);
            if (index !== undefined) {
                this.config.persons.splice(index, 1);
            }
            this.$forceUpdate();
        },
        removeWork(name) {
            const index = this.config.work.findIndex(work => work.name === name);
            if (index !== undefined) {
                this.config.work.splice(index, 1);
            }
            this.$forceUpdate();
        },
        removeTool(name) {
            const index = this.config.tool.findIndex(tool => tool.name === name);
            if (index !== undefined) {
                this.config.tool.splice(index, 1);
            }
            this.$forceUpdate();
        }
    }
});
