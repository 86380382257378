import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CameraControl',
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    computed: {
        status() {
            const status = this.$store.direct.state.rooms.status[this.roomid];
            return status;
        },
        sketchToString() {
            if (this.status === undefined)
                return '';
            return this.status.sketch.map(person => person.name).join();
        },
        pendingState() {
            var _a, _b;
            if ((_a = this.status) === null || _a === void 0 ? void 0 : _a.sensors.find(sensor => sensor.name === 'led_ok' && sensor.status))
                return 'OK';
            if ((_b = this.status) === null || _b === void 0 ? void 0 : _b.sensors.find(sensor => sensor.name === 'led_wrong' && sensor.status))
                return 'WRONG';
            return 'UNKNOWN';
        },
        lastTakenPicture() {
            if (this.status === undefined || this.status.last_taken_picture === null)
                return '';
            return this.status.last_taken_picture;
        }
    },
    methods: {
        overwrite() {
            const button = this.pendingState === 'OK' ? 'button_wrong' : 'button_ok';
            this.$store.direct.dispatch.rooms.setButton({
                roomid: this.roomid,
                button,
                value: 'clicked'
            });
            this.$store.direct.dispatch.rooms.setButton({
                roomid: this.roomid,
                button,
                value: 'released'
            });
        },
        takePicture() {
            const button = 'camerabutton';
            this.$store.direct.dispatch.rooms.setButton({
                roomid: this.roomid,
                button,
                value: 'clicked'
            });
            this.$store.direct.dispatch.rooms.setButton({
                roomid: this.roomid,
                button,
                value: 'released'
            });
        }
    }
});
