// all times in this class are in seconds
export const StringFormatter = {
    durationToString(seconds) {
        const duration = Math.floor(seconds) || 0;
        if (duration < 0) {
            return 'passed';
        }
        const hour = Math.floor(duration / 3600);
        const hoursToSeconds = hour * 3600;
        const minute = Math.floor((duration - hoursToSeconds) / 60);
        const second = duration % 60;
        let durationstring = '';
        durationstring += ('00' + hour).slice(-2) + ':';
        durationstring += ('00' + minute).slice(-2) + ':';
        durationstring += ('00' + second).slice(-2);
        return durationstring;
    },
    timeToString(time) {
        return (new Date(time * 1000)).toISOString();
    }
};
