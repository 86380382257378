import { defineComponent } from 'vue';
import { Stoppedgame } from '@/components/Widgets/GameStates';
import TeamView from '@/components/Teams/TeamView';
import { TeamStatus } from '@/types/teams';
import { Card } from '@/components/UI';
export default defineComponent({
    name: 'PrinterRoom',
    components: {
        TeamView,
        Stoppedgame,
        Card
    },
    data: () => ({
        team: null,
        lastRFID: null,
        lastScanned: 0,
        rfidname: 'printer'
    }),
    computed: {
        now() {
            return this.$store.direct.state.clock.now;
        },
        currentRFID() {
            return this.$store.direct.state.rfid.RFID[this.rfidname];
        }
    },
    watch: {
        currentRFID(newValue) {
            if (newValue.id !== null && newValue.time !== undefined && newValue.time > this.now - 15) {
                if (this.lastRFID !== newValue.id) {
                    // Print page only when fully loaded
                    setTimeout(() => { this.print(); }, 1000);
                }
                this.lastRFID = newValue.id;
                if (newValue.used !== undefined)
                    this.team = newValue.used[TeamStatus.Finished];
                this.lastScanned = newValue.time;
            }
        }
    },
    mounted() {
        this.$store.direct.dispatch.rfid.subscribe(this.rfidname);
    },
    destroyed() {
        this.$store.direct.dispatch.rfid.unsubscribe(this.rfidname);
    },
    methods: {
        print() {
            const div = this.$refs.teamoverview;
            if (div === undefined)
                return;
            const contents = div.innerHTML;
            const frame1 = document.createElement('iframe');
            frame1.name = 'frame1';
            frame1.style.position = 'absolute';
            frame1.style.top = '-1000000px';
            document.body.append(frame1);
            const frameDocument = frame1.contentWindow ? frame1.contentWindow
                // @ts-ignore
                : (frame1.contentDocument.document ? frame1.contentDocument.document
                    : frame1.contentDocument);
            frameDocument.document.open();
            frameDocument.document.write('<html><head><title>DIV Contents</title>');
            frameDocument.document.write('</head><body>');
            frameDocument.document.write(contents);
            frameDocument.document.write('</body></html>');
            frameDocument.document.close();
            setTimeout(() => {
                // @ts-ignore
                window.frames.frame1.focus();
                // @ts-ignore
                window.frames.frame1.print();
                frame1.remove();
            }, 500);
            return false;
        }
    }
});
