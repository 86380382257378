import { defineComponent } from 'vue';
import LedDebug from './LedDebug.vue';
export default defineComponent({
    name: 'SpiderWebDebug',
    components: {
        LedDebug
    },
    props: {
        value: {
            type: String,
            required: true
        }
    },
    computed: {
        status: {
            get() {
                console.log('get', this.value);
                return JSON.parse(this.value);
            },
            set(value) {
                this.$emit('input', JSON.stringify(value));
            }
        }
    },
    methods: {
        updateLed(index, params) {
            console.log('updateLed', index, params);
            const hole = this.status.holes[index];
            if (hole !== undefined) {
                // @ts-ignore
                hole[params.name] = params.value;
                this.$emit('input', JSON.stringify(this.status));
            }
            // [params.name] = params.value
            // this.$emit('input', JSON.stringify(this.status))
        }
    }
});
