import { defineComponent } from 'vue';
import { CheckBox } from '@/components/UI';
export default defineComponent({
    name: 'CatapultEdit',
    components: {
        CheckBox
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    mounted() {
        if (this.config.buttons === undefined) {
            this.config.buttons = [];
        }
    },
    methods: {
        addNewButtonPair() {
            this.config.buttons.push({ button: '', led_from: 0, led_to: 0, disabled: false });
        },
        removeButtonPair(key) {
            this.config.buttons.splice(key, 1);
        }
    }
});
