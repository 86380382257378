import { defineComponent } from 'vue';
export default defineComponent({
    name: 'KeypadEdit',
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    methods: {
        addNewCode() {
            this.config.codes.push({ code: '' });
        },
        removeCode(key) {
            this.config.codes.splice(key, 1);
        },
        addNewButton() {
            this.config.buttons.push({ button: '' });
        },
        removeButton(key) {
            this.config.buttons.splice(key, 1);
        }
    }
});
