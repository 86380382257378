import { StringFormatter } from '@/classes/StringFormatter';
import { getScoresForTeam } from '@/types/teams';
import { Card, Button } from '@/components/UI';
import { defineComponent } from 'vue';
import useRooms from '@/compositions/useRooms';
import TeamGraph from '../TeamGraph';
// FIXME any needs to go
const updateGameSummary = (summary, game) => {
    if (game.level !== undefined && game.level > summary.level) {
        summary.level = game.level;
    }
};
export default defineComponent({
    name: 'TeamView',
    components: {
        Card,
        Button,
        TeamGraph
    },
    props: {
        team: {
            type: Object,
            required: true
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    setup: () => ({
        ...useRooms()
    }),
    data: () => ({
        newRoom: null,
        readOnly: true
    }),
    computed: {
        startTime() {
            if (this.team.start_time === null)
                return 'No Start Time';
            return StringFormatter.timeToString(this.team.start_time);
        },
        gameSummary() {
            const games = {};
            this.team.games.forEach(game => {
                var _a;
                const summary = games[game.room];
                if (summary === undefined) {
                    const lastTime = game.game_finished_time !== undefined ? game.game_finished_time : 0;
                    const level = game.level !== undefined ? game.level : 0;
                    games[game.room] = { count: 1, score: (_a = this.scores[game.room]) !== null && _a !== void 0 ? _a : 0, last_played: lastTime, level };
                }
                else if (game.reset_level !== true) {
                    updateGameSummary(summary, game);
                }
                else {
                    summary.level = 0;
                }
            });
            return games;
        },
        scores() {
            return getScoresForTeam(this.team);
        },
        totalScore() {
            return Object.values(this.scores).reduce((a, b) => a + b, 0);
        }
    },
    methods: {
        updateLevel(roomId, value) {
            const level = Number.parseInt(value !== null && value !== void 0 ? value : '0');
            this.$store.direct.dispatch.teams.resetRoomLevel({ teamId: this.team._id, roomId, level }).then(() => {
                console.log('Level was reset');
            }).catch(error => {
                console.error('Error while resetting team level', error);
            });
        },
        timeToString(time) {
            return StringFormatter.timeToString(time);
        },
        addNewRoom() {
            this.newRoom = {
                teamId: this.team._id,
                roomId: '',
                level: 0
            };
        },
        addRoom() {
            console.log('Need to add room', this.newRoom);
            if (this.newRoom === null)
                return;
            this.$store.direct.dispatch.teams.resetRoomLevel(this.newRoom).then(() => {
                console.log('Level was reset');
            }).catch(error => {
                console.error('Error while resetting team level', error);
            });
            this.newRoom = null;
        }
    }
});
