import { defineComponent } from 'vue';
export default defineComponent({
    name: 'LedStripDisplay',
    props: {
        pattern: {
            type: String,
            required: true
        }
    },
    computed: {
        colors() {
            return this.pattern.split(' ');
        },
        numRows() {
            return Math.ceil(this.colors.length / 10);
        }
    },
    methods: {
        colsInRow(rowNr) {
            if (rowNr <= this.numRows) {
                return 10;
            }
            return this.colors.length % 10;
        },
        onClicked(row, col) {
            const availableColors = ['FFFFFF', 'FF0000', '00FF00', '0000FF'];
            const updateIndex = (row - 1) * 10 + col - 1;
            let pattern = '';
            this.colors.forEach((color, index) => {
                if (index === updateIndex) {
                    const newIndex = (availableColors.indexOf(color) + 1) % 4;
                    pattern += `${availableColors[newIndex]} `;
                }
                else {
                    pattern += `${color} `;
                }
            });
            this.$emit('update', pattern.trimEnd());
        },
        lightStyle(row, col) {
            const color = this.colors[row * 10 + col];
            return {
                border: '1px black solid',
                width: '14px',
                height: '14px',
                borderRadius: '7px',
                backgroundColor: `#${color}`,
                margin: '1px'
            };
        }
    }
});
