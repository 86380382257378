import { defineComponent } from 'vue';
import { Pregame, Stoppedgame, Testgame, Postgame } from '@/components/Widgets/GameStates';
import { Timer, MistakesLeft, Level, InfoBar, FullscreenMessage, RoomDuration, IntroductionSlide } from '@/components/Widgets';
import { Animation } from '@/Animation';
import './RoomLaser.scss';
import { GameType } from '@/types/rooms';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'LaserRoom',
    components: {
        Pregame,
        Postgame,
        Testgame,
        Stoppedgame,
        Timer,
        MistakesLeft,
        Level,
        InfoBar,
        FullscreenMessage,
        RoomDuration,
        IntroductionSlide
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        goMessage: false
    }),
    computed: {
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        ingameStatus() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        timeLeft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        smoke() {
            return this.$store.direct.state.rooms.smoke;
        },
        presence_start_zone() {
            return this.$store.direct.state.rooms.presence_start_zone;
        },
        presence_end_zone() {
            return this.$store.direct.state.rooms.presence_end_zone;
        },
        door_open() {
            return this.$store.direct.state.rooms.door_open;
        },
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        teamType() {
            var _a, _b, _c;
            // FIXME: something wrong here, room id is hardcoded
            return (_c = (_b = (_a = this.$store.direct.state.rooms.status.maze) === null || _a === void 0 ? void 0 : _a.team) === null || _b === void 0 ? void 0 : _b.game_type) !== null && _c !== void 0 ? _c : GameType.Unknown;
        }
    },
    watch: {
        score(newValue, oldValue) {
            if (newValue - oldValue < 0 && this.gamestate === 'playing') {
                new Audio(require('@/assets/sound/laser.mp3')).play();
            }
        },
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gamestate === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        },
        ingameStatus(newValue, oldValue) {
            if (newValue !== oldValue && this.gamestate === 'playing' && newValue === 'wrong') {
                new Audio(require('@/assets/sound/alarm-short.mp3')).play();
            }
        },
        smoke(newValue, oldValue) {
            if (oldValue !== newValue && newValue === false) {
                this.goMessage = true;
                setTimeout(() => { this.goMessage = false; }, 3000);
            }
        }
    },
    methods: {
        onEvent(eventType, data) {
            console.log('NEW EVENT OF TYPE ' + eventType + ':', data);
        },
        updateMistVolume() {
            // @ts-ignore
            this.$refs.smokesound.volume = 0.35;
        },
    }
});
