import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { IntroductionSlide, Timer, Level, MessageBar, FullscreenMessage } from '@/components/Widgets';
import './RoomRepeat.scss';
import { Animation } from '@/Animation';
import { GameType } from '@/types/rooms';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'RoomRepeat',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        Level,
        MessageBar,
        IntroductionSlide,
        FullscreenMessage
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data() {
        return {
            running: false,
            animation: Animation,
            repeatSerie: true
        };
    },
    computed: {
        gameState() {
            return this.$store.direct.state.rooms.gameState;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        config() {
            return this.$store.direct.state.rooms.config[this.roomid];
        },
        levelInfo() {
            var _a;
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if ((status === null || status === void 0 ? void 0 : status.type) === GameType.Arcade) {
                return { ...status.level_info, level: (_a = status.level) !== null && _a !== void 0 ? _a : 0 };
            }
            return undefined;
        },
        ingamestate() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        status() {
            return this.$store.direct.state.rooms.status[this.roomid];
        },
        timeleft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        current_serie() {
            return this.status.current_serie;
        },
        current_target_length() {
            return this.status.current_target_length;
        },
        total_serie() {
            return this.status.total_serie;
        },
        nextButtonUrl() {
            var _a;
            const nextId = this.total_serie[this.current_target_length - 1];
            if (nextId)
                return (_a = this.findButtonById(nextId)) === null || _a === void 0 ? void 0 : _a.url;
            else
                return "";
        },
        pause() {
            return this.ingamestate == 'pause';
        },
        currentSerieLength() {
            return this.current_serie.length;
        },
        pressNextButtonText() {
            return this.current_target_length == 1 ?
                this.translation('startSerieWithButtonNext') : this.translation('repeatSerieWithButtonNext');
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gameState === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        },
        currentSerieLength(newValue, oldValue) {
            console.log('CurrentSerieLength update');
            if (oldValue < newValue) {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
        },
        current_target_length(newValue, oldValue) {
            if (oldValue < newValue) {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
        },
        mistakesLeft(newValue, oldValue) {
            if (oldValue > newValue) {
                new Audio(require('@/assets/sound/brokenglass.mp3')).play();
            }
        }
    },
    methods: {
        levelUp(level) {
            this.$store.direct.dispatch.rooms.setLevel({ roomid: this.roomid, level });
        },
        runningChanged(running) {
            this.running = running;
        },
        findButtonById(buttonId) {
            var _a;
            if (this.config) {
                return (_a = this.config.buttons) === null || _a === void 0 ? void 0 : _a.find((button) => button.id === buttonId);
            }
        },
        getButtonUrl(buttonId, index) {
            var _a;
            if (this.level < 2 && index < this.current_target_length) {
                return (_a = this.findButtonById(buttonId)) === null || _a === void 0 ? void 0 : _a.url;
            }
            else {
                return "https://dekluis.ddns.net/media/repeatroom/unknown.svg";
            }
        },
        currentPosToPress(index) {
            let className = '';
            className += (index == this.current_serie.length) ? 'currentPosToPress' : '';
            className += (index > this.current_target_length - 1) ? ' outOfCurrentTarget' : '';
            return className;
        },
        onEvent(eventType, data) {
            if (eventType === 'clock' && data.status === 'start') {
                new Audio(require('@/assets/sound/go.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up') {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'down' && data.diff > -50) {
                new Audio(require('@/assets/sound/brokenglass.mp3')).play();
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        },
    }
});
