import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { Animation } from '@/Animation';
import { Timer, LevelStatus, Level, CombinationSearch, MessageBar, FullscreenMessage, InfoBar, RoomDuration, IntroductionSlide } from '@/components/Widgets';
import './RoomSearch.scss';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'RoomSearch',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        LevelStatus,
        Level,
        CombinationSearch,
        MessageBar,
        FullscreenMessage,
        InfoBar,
        RoomDuration,
        IntroductionSlide
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    }, setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        animation: Animation,
        scoreDown: false
    }),
    computed: {
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        level() {
            return this.$store.direct.state.rooms.level;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        ingameState() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        runningTimer() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        postgametext() {
            return this.translation('reachedLevel', { level: `${this.level}` });
        },
        levelmessage() {
            var _a;
            return (_a = this.levelInfo[this.level]) !== null && _a !== void 0 ? _a : '';
        },
        levelInfo() {
            return [
                this.translation('searchRoomDescrLevelPersons'),
                this.translation('searchRoomDescrLevelPersons'),
                this.translation('searchRoomDescrLevelPersonsBroken'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools'),
                this.translation('searchRoomDescrLevelPersonsBrokenTools')
            ];
        }
    },
    watch: {
        gamestate(newValue) {
            // TODO use vue for this
            const body = document.querySelectorAll('body')[0];
            if (body === undefined)
                return;
            body.classList.remove('pregame', 'playing', 'postgame', 'stopped', 'introduction');
            body.classList.add(newValue);
        },
        level() {
            this.animation.animateLevelChange(() => { });
            setTimeout(() => Animation.animateLevel('.level .level_icon', () => { }), 3000);
        }
    },
    methods: {
        onEvent(eventType, data) {
            console.log('NEW EVENT OF TYPE ' + eventType + ':', data);
            if (eventType === 'cheat') {
                new Audio(require('@/assets/sound/laser.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up') {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'down') {
                new Audio(require('@/assets/sound/mistake.mp3')).play();
                this.scoreDown = true;
                setTimeout(() => { this.scoreDown = false; }, 3000);
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
            else if (eventType === 'clock' && data.status === 'started') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
        }
    }
});
