import { Selector } from '@/components/UI';
import { SchedulePriority } from '@/types/rooms';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'RoomScheduleEdit',
    components: {
        Selector
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    computed: {
        options() {
            return [
                {
                    text: 'Important',
                    value: SchedulePriority.Important
                },
                {
                    text: 'Normal',
                    value: SchedulePriority.Normal
                },
                {
                    text: 'Minor',
                    value: SchedulePriority.Minor
                },
                {
                    text: "Don't schedule",
                    value: SchedulePriority.Never
                }
            ];
        },
        priority: {
            get() {
                var _a;
                return (_a = this.config.priority) !== null && _a !== void 0 ? _a : SchedulePriority.Normal;
            },
            set(value) {
                this.config.priority = value;
            }
        },
        orderPriority() {
            var _a;
            return (_a = this.config.order_priority) !== null && _a !== void 0 ? _a : (new Array(5)).fill(SchedulePriority.Normal);
        }
    },
    methods: {
        setOrderPriority(index, value) {
            var _a;
            if (!(this.options.map(option => option.value)).includes(value))
                return;
            this.config.order_priority = (_a = this.config.order_priority) !== null && _a !== void 0 ? _a : (new Array(5)).fill(SchedulePriority.Normal);
            this.config.order_priority[index] = value;
        }
    }
});
