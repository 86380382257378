import { defineComponent } from 'vue';
import { RoomLaserEdit } from '../Room/RoomLaser';
import { RoomKeypadEdit } from '../Room/RoomKeypad';
import { RoomReactionEdit } from '../Room/RoomReaction';
import { RoomCombinationEdit } from '../Room/RoomCombination';
import { RoomCameraEdit } from '../Room/RoomCamera';
import { RoomSearchEdit } from '../Room/RoomSearch';
import { RoomCatapultEdit } from '../Room/RoomCatapult';
import { RoomArcadeEdit } from '../Room/RoomArcade';
import { RoomSpiderwebEdit } from '../Room/RoomSpiderweb';
import GeneralRoomEdit from './GeneralRoomEdit';
import RoomCharacterEdit from './RoomCharacterEdit';
import RoomLevelEdit from './RoomLevelEdit';
import RoomSchedulingEdit from './RoomSchedulingEdit';
import { Expander } from '@/components/UI';
export default defineComponent({
    name: 'EditRoom',
    components: {
        GeneralRoomEdit,
        RoomCharacterEdit,
        RoomLevelEdit,
        Expander,
        RoomLaserEdit,
        RoomKeypadEdit,
        RoomReactionEdit,
        RoomCombinationEdit,
        RoomCatapultEdit,
        RoomCameraEdit,
        RoomSearchEdit,
        RoomArcadeEdit,
        RoomSpiderwebEdit,
        RoomSchedulingEdit
    },
    props: {
        config: {
            required: true,
            type: Object
        }
    }
});
