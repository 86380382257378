import { defineComponent } from 'vue';
import { GameType } from '@/types/rooms';
import { Selector } from '@/components/UI';
import './RoomReaction.scss';
export default defineComponent({
    name: 'ReactionControl',
    components: {
        Selector
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        config: null,
        status: null,
        levelOptions: [
            { value: 0, text: 'Level 0' },
            { value: 1, text: 'Level 1' },
            { value: 2, text: 'Level 2' },
            { value: 3, text: 'Level 3' },
            { value: 4, text: 'Level 4' },
            { value: 5, text: 'Level 5' },
            { value: 6, text: 'Level 6' },
            { value: 7, text: 'Level 7' },
            { value: 8, text: 'Level 8' },
            { value: 9, text: 'Level 9' },
            { value: 10, text: 'Level 10' }
        ]
    }),
    computed: {
        forbiddenColors() {
            if (this.status === null)
                return [];
            else
                return this.status.forbidden_colors;
        },
        activeButton() {
            if (this.status === null || this.status.selected_button === undefined)
                return '';
            else
                return this.status.selected_button;
        },
        activeButtonColor() {
            return this.getButtonColor(this.activeButton);
        },
        activeButtonLineColor() {
            let isForbidden = false;
            if (this.status === null || this.status.selected_button === undefined)
                return 'no-forbidden';
            else {
                try {
                    const selectedButton = this.status.selected_button;
                    isForbidden = this.status.forbidden_colors.filter(colorObject => colorObject === this.getButtonColor(selectedButton)).length > 0;
                }
                catch (_a) {
                    console.log('activeButtonLineColor failed');
                    return '';
                }
                if (isForbidden)
                    return 'forbidden';
                else
                    return 'no-forbidden';
            }
        },
        level: {
            get() {
                if (this.status === null)
                    return 0;
                const level = Number(this.status.level);
                return level ? level : 0;
            },
            set(levelnr) {
                this.updateLevel(levelnr);
            }
        },
    },
    async mounted() {
        try {
            await this.$store.direct.dispatch.rooms.subscribe(this.roomid);
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if ((status === null || status === void 0 ? void 0 : status.type) === GameType.Reaction) {
                this.status = status;
            }
            this.config = this.$store.direct.state.rooms.config[this.roomid];
        }
        catch (error) {
            console.error('Could not subscribe room in ReactionControl', error);
        }
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
    },
    methods: {
        click(buttonId) {
            const parameters = {
                roomid: this.roomid,
                button: buttonId,
                value: 'clicked'
            };
            this.$store.direct.dispatch.rooms.setButton(parameters);
            setTimeout(() => {
                parameters.value = 'released';
                this.$store.direct.dispatch.rooms.setButton(parameters);
            }, 200);
        },
        getButtonColor(buttonId) {
            if (this.config === null)
                return '';
            const button = this.config.buttons.find(buttonObject => buttonObject.button === buttonId);
            return (button === null || button === void 0 ? void 0 : button.color) || '';
        },
        updateLevel(level) {
            this.$store.direct.dispatch.rooms.setLevel({ roomid: this.roomid, level });
        },
    }
});
