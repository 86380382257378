import { defineComponent } from 'vue';
import { GameState, RoomStatusMessageCode } from '@/types/rooms';
import { MessageBar } from '@/components/Widgets';
import RoomLaser from './RoomLaser';
import RoomKeypad from './RoomKeypad';
import RoomReaction from './RoomReaction';
import RoomScore from './RoomScore';
import RoomPrinter from './RoomPrinter';
import RoomCamera from './RoomCamera';
import RoomCombination from './RoomCombination';
import RoomSearch from './RoomSearch';
import RoomCatapult from './RoomCatapult';
import RoomArcade from './RoomArcade';
import RoomSpiderweb from './RoomSpiderweb';
import RoomRepeat from './RoomRepeat';
import RoomJungle from './RoomJungle';
export default defineComponent({
    name: 'Room',
    title: 'Beat Out!',
    components: {
        RoomLaser,
        RoomScore,
        RoomKeypad,
        RoomPrinter,
        RoomCamera,
        RoomReaction,
        RoomCombination,
        RoomSearch,
        RoomCatapult,
        MessageBar,
        RoomArcade,
        RoomSpiderweb,
        RoomRepeat,
        RoomJungle
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    computed: {
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        gameState() {
            return this.$store.direct.state.rooms.gameState;
        },
        config() {
            return this.$store.direct.state.rooms.config[this.roomid];
        },
        status() {
            return this.$store.direct.state.rooms.status[this.roomid];
        },
        message() {
            var _a;
            // Should be refactored to be 'schedule messages'
            if (((_a = this.status) === null || _a === void 0 ? void 0 : _a.state) === GameState.Playing)
                return undefined;
            if (this.status === undefined)
                return undefined;
            if (this.status.message.timer < 1)
                return undefined;
            const time = Math.floor(this.status.message.timer);
            if (this.status.message.code === RoomStatusMessageCode.WRONG_ROOM) {
                return `Je bent in de verkeerde kamer, ga naar de ${this.status.message.message} kamer.`;
            }
            return `Je kan deze kamer spelen over ${time} seconden. Badge dan opnieuw.`;
        },
        name() {
            return (this.config && this.config.name) || 'Loading...';
        }
    },
    mounted() {
        this.$store.direct.dispatch.rooms.subscribeStatus(this.roomid);
        this.$store.direct.dispatch.rooms.subscribeRoomActions(this.onEvent);
    },
    destroyed() {
        this.$store.direct.dispatch.rooms.unsubscribeStatus(this.roomid);
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
        // TODO unsubscribe subscribeRoomActions
    },
    methods: {
        onEvent(type, data) {
            try {
                // @ts-ignore   TODO fix
                this.$refs.room.onEvent(type, data);
            }
            catch (error) {
                console.log('Room has errors handling onEvent', error);
            }
        }
    }
});
