import { defineComponent } from 'vue';
export default defineComponent({
    name: 'LedDebug',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    methods: {
        updateLed(name, value) {
            this.$emit('updateLed', { name, value });
        }
    }
});
