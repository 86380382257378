import { defineComponent } from 'vue';
import './SearchControl.scss';
import { cloneDeep } from 'lodash';
import { GameType } from '@/types/rooms';
export default defineComponent({
    name: 'CluedoControl',
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        config: null,
        status: null
    }),
    computed: {
        rooms() {
            if (this.status === null || this.status.rooms === undefined) {
                return [];
            }
            const rooms = cloneDeep(this.status.rooms);
            rooms.forEach(room => {
                const person = this.getPersonByRoomName(room.name);
                if (person !== undefined) {
                    room.person_name = person.name;
                    room.person_url = person.url;
                }
                const work = this.getWorkByRoomName(room.name);
                if (work !== undefined) {
                    room.work_name = work.name;
                    room.work_url = work.url;
                }
                const tool = this.getToolByRoomName(room.name);
                if (tool !== undefined) {
                    room.tool_name = tool.name;
                    room.tool_url = tool.url;
                }
            });
            return rooms;
        }
    },
    async mounted() {
        try {
            await this.$store.direct.dispatch.rooms.subscribe(this.roomid);
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if ((status === null || status === void 0 ? void 0 : status.type) === GameType.Cluedo) {
                this.status = status;
            }
            this.config = this.$store.direct.state.rooms.config[this.roomid];
        }
        catch (error) {
            console.error('Could not subscribe room in CluedoControl', error);
        }
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
    },
    methods: {
        getLocationByName(name) {
            if (this.config === null) {
                return;
            }
            return this.config.locations.find(location => location.name === name);
        },
        getPersonByRoomName(name) {
            const room = this.getLocationByName(name);
            if (room === undefined || this.config === null) {
                return;
            }
            const personId = room.windows.person.id;
            return this.config.persons.find(person => person.name === personId);
        },
        getWorkByRoomName(name) {
            const room = this.getLocationByName(name);
            if (room === undefined || this.config === null) {
                return;
            }
            const workId = room.windows.work.id;
            return this.config.work.find(work => work.name === workId);
        },
        getToolByRoomName(name) {
            const room = this.getLocationByName(name);
            if (room === undefined || this.config === null) {
                return;
            }
            const toolId = room.windows.tool.id;
            return this.config.tool.find(tool => tool.name === toolId);
        },
        getLedVariant(status) {
            if (status === 'red') {
                return 'danger';
            }
            else if (status === 'green') {
                return 'success';
            }
            else {
                return 'dark';
            }
        },
        updateLedStatus(location, window, currentStatus) {
            const options = ['off', 'green', 'red'];
            let index = options.findIndex(option => option === currentStatus) + 1;
            index %= 3;
            const parameters = {
                action: 'updateWindow',
                location,
                window,
                status: options[index]
            };
            this.$store.direct.dispatch.rooms.setCustomAction({ roomid: this.roomid, action: 'custom', params: parameters });
        },
        updateCombination() {
            const parameters = {
                action: 'newCombination',
            };
            this.$store.direct.dispatch.rooms.setCustomAction({ roomid: this.roomid, action: 'custom', params: parameters });
        },
        click(buttonId) {
            const parameters = {
                roomid: this.roomid,
                button: buttonId,
                value: 'clicked'
            };
            this.$store.direct.dispatch.rooms.setButton(parameters);
            setTimeout(() => {
                parameters.value = 'released';
                this.$store.direct.dispatch.rooms.setButton(parameters);
            }, 200);
        }
    }
});
