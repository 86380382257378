import { defineComponent } from 'vue';
export default defineComponent({
    name: 'RoomLevelEdit',
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    computed: {
        parameters() {
            const parameters = [];
            Object.keys(this.config.difficulty[0]).forEach(parameter => {
                parameters.push({
                    name: parameter,
                    type: typeof this.config.difficulty[0][parameter]
                });
            });
            return parameters;
        },
        levels() {
            const parameters = [];
            for (let index = 0; index < this.config.difficulty.length; ++index) {
                parameters.push(index);
            }
            return parameters;
        }
    }
});
