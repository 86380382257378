import RoomSummary from '@/components/Rooms/RoomSummary';
import './RoomsTab.scss';
import { GameType } from '@/types/rooms';
import { defineComponent } from 'vue';
import useRooms from '@/compositions/useRooms';
import { useToast } from 'vue-toastification';
export default defineComponent({
    name: 'RoomsTab',
    components: {
        RoomSummary
    },
    setup: () => ({
        ...useRooms(),
        toast: useToast()
    }),
    data: () => ({
        newRoom: { name: '', type: '' },
        roomTypes: [{ value: 'laser', text: 'Laser Room' },
            { value: 'reaction', text: 'Reaction Room' },
            { value: 'keypad', text: 'Keypad Room' },
            { value: 'combination', text: 'Combination Room' },
            { value: 'camera', text: 'Camera Room' },
            { value: 'cluedo', text: 'Cluedo Room' },
            { value: 'catapult', text: 'Catapult Room' },
            { value: 'score', text: 'Score Overview' }],
        errormessage: ''
    }),
    computed: {
        goodRooms() {
            if (this.rooms === undefined)
                return [];
            return Object.values(this.rooms).filter(room => room._id !== undefined);
        }
    },
    methods: {
        startNewRoom() {
            this.newRoom = { name: '', type: GameType.Unknown, id: '', introduction: [], character: {} };
            this.errormessage = '';
        },
        async addRoom() {
            if (this.newRoom.name === '') {
                this.errormessage = 'Please fill in a name';
                return;
            }
            if (this.newRoom.type === '') {
                this.errormessage = 'Please fill in a type';
                return;
            }
            try {
                await this.$store.direct.dispatch.rooms.add(this.newRoom);
                this.toast.success(`Room ${this.newRoom.name} has been created`);
            }
            catch (error) {
                this.toast.error(`Room ${this.newRoom.name} was not created ${error}`);
            }
        }
    }
});
