import { defineComponent } from 'vue';
import { CheckBox } from '@/components/UI';
export default defineComponent({
    name: 'CombinationEdit',
    components: {
        CheckBox
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    methods: {
        addNewButton() {
            this.config.buttons.push({ id: '', name: '', image: '', disabled: false });
        },
        removeButton(key) {
            this.config.buttons.splice(key, 1);
        },
        addNewMotion() {
            this.config.motion.push({ id: '' });
        },
        removeMotion(key) {
            this.config.motion.splice(key, 1);
        }
    }
});
