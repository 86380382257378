import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ChromaKeySliders',
    props: {
        pickingMode: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        mypickingmode: false
    }),
    watch: {
        pickingMode() {
            console.log('pickingMode', this.pickingMode, ' ', this.mypickingmode);
            if (this.pickingMode !== this.mypickingmode) {
                this.mypickingmode = this.pickingMode;
            }
        },
        mypickingmode() {
            console.log('mypickingmode', this.pickingMode, ' ', this.mypickingmode);
            if (this.pickingMode !== this.mypickingmode) {
                this.$emit('setPickingMode', this.mypickingmode);
            }
        }
    },
    methods: {
        pick() {
            this.$emit('setPickingMode', true);
        },
        colorPicked(color) {
            this.value.screen = color;
            this.$emit('setPickingMode', false);
        }
    }
});
