import { defineComponent } from 'vue';
import { Card } from '@/components/UI';
export default defineComponent({
    name: 'RoomInfo',
    components: {
        Card
    },
    props: {
        room: {
            type: Object,
            required: true
        }
    }
});
