import { defineComponent } from 'vue';
import { CheckBox } from '@/components/UI';
export default defineComponent({
    name: 'LaserEdit',
    components: {
        CheckBox
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    methods: {
        addNewLaser() {
            this.config.lasers.push({ sender: '', receiver: '', disabled: false });
        },
        removeLaser(key) {
            this.config.lasers.splice(key, 1);
        }
    }
});
