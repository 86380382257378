const fragmentShader = `

precision mediump float;
varying vec2 vTexCoord;
uniform sampler2D source;
uniform vec4 screen;
uniform float screenWeight;
uniform float balance;
uniform float clipBlack;
uniform float clipWhite;
uniform int enabled;
varying float screenSat;
varying vec3 screenPrimary;

void main(void) {
    float pixelSat, secondaryComponents;
    vec4 sourcePixel = texture2D(source, vTexCoord);
    if (enabled == 0) {
      gl_FragColor = vec4(sourcePixel.rgb, 1.0);
      return;
    }
  float fmin = min(min(sourcePixel.r, sourcePixel.g), sourcePixel.b);
  float fmax = max(max(sourcePixel.r, sourcePixel.g), sourcePixel.b);
  vec3 pixelPrimary = step(fmax, sourcePixel.rgb);
  secondaryComponents = dot(1.0 - pixelPrimary, sourcePixel.rgb);
  pixelSat = fmax - mix(secondaryComponents - fmin, secondaryComponents / 2.0, balance);
  // solid pixel if primary color component is not the same as the screen color
  float diffPrimary = dot(abs(pixelPrimary - screenPrimary), vec3(1.0));
  float solid = step(1.0, step(pixelSat, 0.1) + step(fmax, 0.1) + diffPrimary);
  /*
  Semi-transparent pixel if the primary component matches but if saturation is less
  than that of screen color. Otherwise totally transparent
  */
  float alpha = max(0.0, 1.0 - pixelSat / screenSat);
  alpha = smoothstep(clipBlack, clipWhite, alpha);
  vec3 rgb = vec3(sourcePixel.rgb - (1.0 - alpha) * screen.rgb * screenWeight) / max(0.00001, alpha);
  vec4 semiTransparentPixel = vec4(rgb, alpha);
  vec4 pixel = mix(semiTransparentPixel, sourcePixel, solid);
  gl_FragColor = pixel;
}

`;
export default fragmentShader;
