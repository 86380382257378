import { defineComponent } from 'vue';
import LedStripDebug from './LedStripDebug.vue';
import LedStripDisplay from './LedStripDisplay.vue';
import SpiderWebDebug from './SpiderWebDebug.vue';
import LedDebug from './LedDebug.vue';
import GameStateChanger from './GameStateChanger.vue';
import { TeamStatus } from '@/types/teams';
import { Card, Selector, Table, ButtonGroup } from '@/components/UI';
import useMobile from '@/compositions/useMobile';
export default defineComponent({
    name: 'RoomDebug',
    components: {
        LedStripDebug,
        LedStripDisplay,
        LedDebug,
        GameStateChanger,
        Card,
        Table,
        Selector,
        ButtonGroup,
        SpiderWebDebug
    },
    props: {
        sensors: {
            type: Array,
            required: true
        },
        gameState: {
            type: String,
            required: true
        },
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useMobile()
    }),
    data: () => ({
        perpage: 215,
        text: '',
        fields: [
            { key: 'type', label: 'Type', sortable: true },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'status', label: 'Status', sortable: true },
            { key: 'note', label: 'Notes', sortable: true },
            { key: 'actions', label: 'Action', sortable: true }
        ],
        gameStatus: 'stopped',
        buttonOptions: [
            { value: 'released', text: 'released' },
            { value: 'clicked', text: 'clicked' }
        ],
        selectedRFID: '',
        status: null,
        config: null
    }),
    computed: {
        teams() {
            return this.$store.direct.state.teams.active;
        },
        level: {
            get() {
                var _a;
                return ((_a = this.status) === null || _a === void 0 ? void 0 : _a.level) ? this.status.level : 0;
            },
            set(levelnr) {
                this.updateLevel(levelnr);
            }
        },
        currentInput() {
            var _a, _b;
            return (_b = (_a = this.status) === null || _a === void 0 ? void 0 : _a.input) !== null && _b !== void 0 ? _b : '';
        },
        sensorlist() {
            const sensors = [];
            this.sensors.forEach(element => {
                const sensor = {
                    type: element.type,
                    name: element.name,
                    status: element.status,
                    note: this.findInfoOnButton(element.name)
                };
                sensors.push(sensor);
            });
            return sensors;
        },
        rfidOptions() {
            const options = [{ value: '', text: 'Scan team' }];
            Object.values(this.teams).forEach(team => {
                const member = team.members[0];
                if (member !== undefined)
                    options.push({ text: team.name, value: member.rfid });
            });
            Object.values(this.specialRFIDs).forEach(rfid => {
                options.push({ text: rfid.text, value: rfid.rfid });
            });
            return options;
        },
        specialRFIDs() {
            return this.$store.direct.state.rfid.specialRFID;
        },
        levelOptions() {
            const levels = [];
            for (let level = 0; level < 50; level++)
                levels.push({ value: level, text: `Level ${level + 1}` });
            return levels;
        },
    },
    watch: {
        gameState(value) {
            this.gameStatus = value;
        }
    },
    mounted() {
        var _a, _b;
        this.gameStatus = this.gameState;
        this.$store.direct.dispatch.teams.subscribe(TeamStatus.Active);
        this.$store.direct.dispatch.rooms.subscribeStatus(this.roomid);
        this.$store.direct.dispatch.rooms.subscribe(this.roomid);
        this.status = (_a = this.$store.direct.state.rooms.status[this.roomid]) !== null && _a !== void 0 ? _a : null;
        this.config = (_b = this.$store.direct.state.rooms.config[this.roomid]) !== null && _b !== void 0 ? _b : null;
        this.$store.direct.dispatch.rfid.subscribe(undefined);
    },
    destroyed() {
        this.$store.direct.dispatch.teams.unsubscribe(TeamStatus.Active);
        this.$store.direct.dispatch.rooms.unsubscribeStatus(this.roomid);
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
        this.$store.direct.dispatch.rfid.unsubscribe(undefined);
    },
    methods: {
        updateLed(params) {
            this.$emit('updateLed', params.name, params.value);
        },
        updateLedStrip(pattern, name) {
            this.$emit('updateLedStrip', name, pattern);
        },
        updateKeyboard(value) {
            if (value.length > 0) {
                this.$store.direct.dispatch.rooms.setKeyboard({ roomid: this.roomid, keyboard: 'codeinput', value });
            }
            return '';
        },
        updateLevel(level) {
            this.$store.direct.dispatch.rooms.setLevel({ roomid: this.roomid, level });
        },
        buttonChanged(source, event) {
            this.$emit('updateButton', source, event);
        },
        sendRFID(source) {
            this.$store.direct.dispatch.rooms.setRFID({ roomid: this.roomid, rfid: source, value: this.selectedRFID });
        },
        findInfoOnButton(id) {
            var _a;
            if (((_a = this.config) === null || _a === void 0 ? void 0 : _a.buttons) === undefined) {
                return '';
            }
            // @ts-ignore
            const note = this.config.buttons.find(button => button.id === id);
            if (note !== undefined && note.name !== undefined) {
                return note.name;
            }
            return '';
        },
        updateSpiderWeb(value, name) {
            console.log('updateSpiderWeb', name, value);
            this.$emit('updateSpiderWeb', name, value);
        }
    }
});
