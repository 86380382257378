import { defineComponent } from 'vue';
import { CheckBox, Selector } from '@/components/UI';
export default defineComponent({
    name: 'ReactionEdit',
    components: {
        CheckBox,
        Selector
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        colors: [
            { value: 'unknown', text: 'unknown', default: true },
            { value: 'green', text: 'green' },
            { value: 'red', text: 'red' },
            { value: 'blue', text: 'blue' },
            { value: 'yellow', text: 'yellow' }
        ]
    }),
    methods: {
        addNewButtonPair() {
            this.config.buttons.push({ button: '', led: '', region: 0, color: 'unknown', disabled: false });
        },
        removeButtonPair(key) {
            this.config.buttons.splice(key, 1);
        }
    }
});
