import { defineComponent } from 'vue';
import { GameState } from '@/types/rooms';
import { Selector } from '@/components/UI';
export default defineComponent({
    name: 'GameStateChanger',
    components: {
        Selector
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    data: () => ({
        options: [
            { value: GameState.Stopped, text: 'Stopped' },
            { value: GameState.Introduction, text: 'Introduction' },
            { value: GameState.Pregame, text: 'Pregame' },
            { value: GameState.Playing, text: 'Playing' },
            { value: GameState.PostGame, text: 'Postgame' },
            { value: GameState.Testing, text: 'Testing' }
        ]
    }),
    computed: {
        gameState: {
            get() {
                const status = this.$store.direct.state.rooms.status[this.roomid];
                if (status)
                    return status.state;
                return GameState.Stopped;
            },
            set(_value) { }
        }
    },
    mounted() {
        this.$store.direct.dispatch.rooms.subscribe(this.roomid);
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
    },
    methods: {
        updateStatus(state) {
            this.$store.direct.dispatch.rooms.setState({ roomid: this.roomid, state });
        }
    }
});
